<script>
import Layout from "../../layouts/horizontal";
import http from '@/http'

export default {
  components: {
    Layout
  },
  data() {
    return {
      title: 'Demo Requests',
      isBusy: false,
      items: []
    };
  },
  validations: {
  
  },
  created(){
    this.load();
  },
  computed: {
  
  },
  watch: {
   
  },
  methods: {
    async load()  {
      this.isBusy = true;
      const resp = await http.get(`api/demo-requests`, {
      });
      this.items = resp.data;
      this.isBusy = false;  
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0">
            <span  class="title-content">
                {{ title }}
                <span class="mr-2" v-if="isBusy">
                  <i class="fa fa-spin fa-spinner"/>
                </span>
              </span>
          </h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-striped table-sm table-hover ">
            <thead>
              <tr>
                <th>
                  Created At
                </th>
                <th>
                  Company
                </th>
                <th>
                  Person Name
                </th>
                <th>
                  Person Email
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="items.length === 0">
                <td colspan="100">
                  <span class="text-muted">No records found...</span>
                </td>
              </tr>
              <tr v-for="req in items" :key="req.id">
                <td>
                 {{req.createdAt | moment("LLL")}}
                </td>
                <td>
                  {{req.companyName}}
                </td>
                 <td>
                  {{req.personName}}
                </td>
                <td>
                  {{req.personEmail}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>